<template>
    <div>
        <el-card style="height: calc(100vh - 160px)">
          <el-row :gutter="20">
            <el-col :span="21">
              <baidu-map class="baidumap" style="height: calc(100vh - 200px);"
                         :scroll-wheel-zoom="true" :center="map.center"
                         :zoom="map.zoom" :max-zoom="18" :min-zoom="10" :mapStyle="mapStyle"
                         @ready="handlerReady">
                  <!--       渲染多边形（乡镇地图）         -->
                  <bm-polygon :path="polygonPath" stroke-color="blue" fill-color="transparent" :stroke-weight="2" :editing="false"
                              fillColor="orange" strokeColor="orange"></bm-polygon>
                <!--      所有站点标记          -->
                <template v-for="(it,index) in ms">
                  <bm-label :content="it.shortName" :position="it.position" :offset="{width: -it.tl*6, height: -48}"
                            :labelStyle="{color: 'red', fontSize : '12px', border: '2px solid orange', borderRadius: '8px', backgroundColor: 'orange'}"
                            :title="it.name"/>
                  <bm-marker :key="it.id" :position="it.position"
                      @click="markclick(it,index)" @mouseover="markover(it,index)" @mouseout="markout(it,index)">
                    <bm-info-window @close="infoWindowClose" @open="infoWindowOpen"
                                    :show="it.show" :position="it.position">
                      <div style="color:#000;font-size:16px;">
                        <div style="display: flex;flex-direction:row;align-items: center; justify-content: space-between;">
                          <div style="padding:5px; font-size: 14px; font-weight: 600;">{{ it.name }}</div>
                          <div v-if="clickFlag" class="goto" @click="end=it.position; showDriving=true;showBC=true;"><i class="el-icon-position"></i>导航</div>
                        </div>
                        <bm-control anchor="BMAP_ANCHOR_TOP_RIGHT" :offset="{width: 20, height: 20}" style="display: flex;flex-direction: column;align-items: center;">
                          <el-button type="primary" plain size="medium" round @click="!showBC?backToCenter():''">复位地图原状</el-button><div style="height: 20px;"></div>
                          <el-button v-if="showBC" type="success" plain size="medium" round @click="showDriving=false;showBC=false;">关闭规划路线</el-button>
                        </bm-control>
                        <el-card v-if="!clickFlag" style="color:#000;font-size:12px; " body-style="padding: 5px">
                          <div style="margin-top:0px;width:220px">站号：{{ it.number }}</div>
                          <div style="margin-top:5px;width:220px">地址：{{ it.address }}</div>
                          <div style="margin-top:5px;width:220px">经纬度：{{ it.longitude2 + '/' + it.latitude2}}</div>
                          <div style="margin-top:5px;width:220px">海拔高度：{{ it.altitude }}米</div>
                          <div style="margin-top:5px;width:220px">台站类型：{{ it.detailedType}}</div>
                          <div style="margin-top:5px;width:220px">建站时间：{{ it.createStationTime }}</div>
                        </el-card>
                        <el-card v-else style="color:#000;font-size:12px;" body-style="padding: 5px">
                          <div v-for="item in list" :key="item.id" class="openbox" @click="openbox(item)">{{ item.name }}</div>
                          <div style="display: flex; justify-content: flex-end">
                            <el-button type="success" size="mini" @click="closeInfo">关闭</el-button>
                            <el-button type="primary" size="mini" @click="closeInfo2(it.number)">图集</el-button>
                            <el-button type="info" size="mini" >信息</el-button>
                          </div>
                        </el-card>
                      </div>
                    </bm-info-window>
                    <bm-context-menu>
                      <bm-context-menu-item :callback="driving" text="规划本局到此站点路线"></bm-context-menu-item>
                    </bm-context-menu>
                  </bm-marker>
                  <!--图集-->
                  <el-image v-if="it.imgPath!==false" :id="it.number"
                            style="width: 10px; height: 10px"
                            :src="require('@/assets/imgs/logo.png')"
                            :preview-src-list="it.imgPath?it.imgPath:[]">
                  </el-image>
                </template>
<!-- @searchcomplete="handleSearchComplete"     @stop="reset"          {lng: 108.669468, lat: 24.490966}-->
                <bm-driving
                    :start="start"
                    :end="end"
                    :panel="false"
                    v-if="showDriving"
                    :autoViewport="true"
                ></bm-driving>
              </baidu-map>
            </el-col>
            <el-col :span="3">
              <div style="height: calc(100vh - 200px); overflow-y: auto;">
                <el-tree :data="data" :props="defaultProps" @node-click="handleNodeClick"></el-tree>
              </div>
            </el-col>
          </el-row>
        </el-card>
      <!--装备详情弹窗-->
      <div class="LjDialog">
        <el-dialog :visible.sync="dialogTableVisible" width="380px" top="10vh" @opened="dialogOpen">
          <template slot="title">
            <div style="font-weight: 600; font-size: 24px; color: #2A60C9; "><i class="el-icon-menu"></i><span>装备详情</span></div>
          </template>
          <template slot="footer">
            <el-button type="primary" @click="dialogTableVisible=false">关 闭</el-button>
          </template>
          <table  border="1" align="center">
            <tr>
              <td width="100">二维码</td><td>
                <div style="display: flex; flex-direction: row;align-items: center;justify-content: space-between">
                  <div><div id="qrcode" ref="qrcode" /></div>
                  <div v-if="list1.oldcode!='无'">
                    <el-radio-group v-model="radio" @input="radioChange">
                      <div style="display: flex; flex-direction: column;align-items: center;justify-content: space-between;padding: 30px">
                        <div><el-radio border :label="0">新码</el-radio></div>
                        <div style="padding-top: 0px;"><el-radio border :label="1">旧码</el-radio></div>
                      </div>

                    </el-radio-group>
                  </div>
                </div>
              </td>
            </tr>
            <tr><td>备件名称</td><td>{{list1.name}}</td></tr>
            <tr><td>生产商</td><td>{{list1.manufacturer}}</td></tr>
            <tr><td>状态</td><td>{{list1.status}}</td></tr>
            <tr><td>检定有效期</td><td>{{list1.validperiod==='2099-12-31'?'':list1.validperiod}}</td></tr>
          </table>
        </el-dialog>
      </div>
    </div>
</template>

<script>
import QRCode from 'qrcode2'
    //引入组件
    import {
        BaiduMap,
        BmControl,
        BmView,
        BmAutoComplete,
        BmLocalSearch,
        BmMarker,
        BmGeolocation,
    } from "vue-baidu-map";

import axios from 'axios'
    export default {
        data() {
            return {
              polygonPath: [],
              radio: 0,//亲码
              data: [],
              defaultProps: {
                children: 'children',
                label: 'label'
              },
              url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
              qrUrl: location.href, // 本页面地址
              qrcode: null,
              dialogTableVisible: false,
              dialogFormVisible: false,
              stations: [],
              lists: [],
              list: [],
              list1: {},
              clickFlag: false,
              markerNumber: 0,
              currentMarker: 0,
              //定位位置信息
              center: {lng: 108.5, lat: 24.5},
              //检索关键字
              keyword: "",
              markerIcon: "", //标注图片
              isShowInfo: true,
              map: {
                  center: {lng: 108.5, lat: 24.5},
                  zoom: 11,
              },
              mapStyle: {
                  styleJson: [
                      {
                          "featureType": "all",
                          "elementType": "geometry",
                          "stylers": {
                              "hue": "#007fff",
                              "saturation": 89
                          }
                      },{
                          "featureType": "water",
                          "elementType": "all",
                          "stylers": {
                              "color": "#ffffff"
                          }
                      }
                  ]
              },
              ms:[ ],
              start: '河池市宜州区气象局',
              end: {lng:'108.644167', lat:'24.481389'},
              showDriving: false,
              showBC: false,
              user: JSON.parse(localStorage.getItem('user') || '{}'),
              myBMap: '',
              myMap: '',
              myCenter: { lng: 0, lat: 0 },
              myZoom: 3,
              jsonData: [],
            };
        },
        //需要引入的组件
        components: {
            BaiduMap,
            BmControl,
            BmView,
            BmAutoComplete,
            BmLocalSearch,
            BmMarker,
            BmGeolocation,
        },
        mounted(){  //读取站点和装备信息
            this.request.get('/stationinfo').then(res => {
                if (res.code === '200') {
                  this.stations=res.data
                  var arr=this.ms
                  var tree=[]
                  var treeData=this.data
                  // console.log("讲得站点：",this.stations)
                  this.stations.forEach(function(value,index,array){
                    if(value.shortName.length<4){
                      tree.push({ label: value.shortName})
                    }
                    arr.push({
                      id: index,
                      show: false,
                      number: value.number,
                      name: value.name,
                      address: value.address,
                      position: {lng: value.longitude1, lat: value.latitude1},
                      longitude2: value.longitude2,
                      latitude2: value.latitude2,
                      altitude: value.altitude,
                      detailedType: value.detailedType,
                      createStationTime: value.createStationTime,
                      shortName: value.shortName,
                      imgPath: value.imgPath ? value.imgPath.split(',') : '',
                    tl: value.shortName.length,
                    })
                  });
                  tree.forEach(function(value1,index1,array1){
                    var childrenArr=[]
                    res.data.forEach(function(value2,index2,array2){
                      // console.log(value2.shortname.indexOf(value1.label))
                        if(value2.shortName.indexOf(value1.label)===0){
                          // console.log("子节点：",value2.shortName)
                          childrenArr.push({ label: value2.shortName, id: index2, lng:value2.longitude1, lat:value2.latitude1})
                        }
                    });

                    treeData.push({
                      label: value1.label,
                      children: childrenArr
                    })
                  });
                  // console.log('###站点列表：', this.ms)
                } else {
                    this.$message.error(res.msg)
                }
            })
          //
          this.request.get('/equipment').then(res => {
                if (res.code === '200') {
                  var lists=[]
                  this.stations.forEach((item1,i,array)=> {
                    var arr=[]
                    res.data.forEach((item2,j,array)=> {
                      if(item2.currentLocation.includes(item1.name)){
                        arr.push(item2)
                      }
                    })
                    // console.log(item1.name,arr)
                    lists.push(arr)
                  })
                    this.lists=lists
                  // console.log(this.lists)
                }
          })

          window.cl = this.cl

        },
        methods: {
          radioChange(val){
            var code=val===0?this.list1.qrcode:this.list1.oldCode
            this.qrcode.clear(); // 清除原来的二维码, 这里是动态生成DIV， 所以this.qrcode
            this.qrcode.makeCode(code)
          },
          cl() {
            let srcUrl=document.querySelector('.el-image-viewer__img').src;
            this.downloadIamge(srcUrl,srcUrl)
          },
          checkImage(){//这个事件要绑定el-image父级盒子上
            // console.log('点击事件');
            let a=document.querySelector('.el-image-viewer__actions__inner');
            // $(a).append(`<i class="el-icon-download" onclick="installImage()"></i>`)
            // a.append( `<i class="el-icon-download" onclick="cl()"></i>`)
            console.log(a)
            let ff = document.createElement('i')
            ff.innerHTML = `<i class="el-icon-download" onclick="cl()"></i>`
            a.appendChild(ff)
          },
          downloadIamge(imgsrc, name) {//下载图片地址和图片名
            let image = new Image();
            // 解决跨域 Canvas 污染问题
            image.setAttribute("crossOrigin", "anonymous");
            image.onload = function() {
              let canvas = document.createElement("canvas");
              canvas.width = image.width;
              canvas.height = image.height;
              let context = canvas.getContext("2d");
              context.drawImage(image, 0, 0, image.width, image.height);
              let url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
              let a = document.createElement("a"); // 生成一个a元素
              let event = new MouseEvent("click"); // 创建一个单击事件
              a.download = name || "photo"; // 设置图片名称
              a.href = url; // 将生成的URL设置为a.href属性
              a.dispatchEvent(event); // 触发a的单击事件
            };
            image.src = imgsrc;
          },
          closeInfo2(id){
            // 模拟点击事件
            const button = document.getElementById(id);
            button.click();
            this.dialogFormVisible=true
          },
            closeInfo(){
              this.ms[this.markerNumber].show=false;
              this.clickFlag=false
            },
            markclick(it,index){
                this.markerNumber=index;
              this.ms.forEach((item,index,arr)=>{
                arr[index].show=false;
              })
                this.ms[index].show=true;
              // console.log(this.ms)
                // this.ms[index].mess="true";
              this.clickFlag=true
              this.list=this.lists[this.markerNumber]

            },
          openbox(item){
            this.dialogTableVisible=true
            this.list1=item
          },
          dialogOpen(){
            this.radio=0
            if (this.qrcode != null) {
              // this.$refs.qrcode.innerHTML = ''; //这个也可以清除原二维码
              this.qrcode.clear(); // 清除原来的二维码, 这里是动态生成DIV， 所以this.qrcode
              this.qrcode.makeCode(this.list1.qrcode)
            }else {
              this.qrcode = new QRCode('qrcode', {
                typeNumber: 4,
                width: 100, // 设置宽度，单位像素
                height: 100, // 设置高度，单位像素
           //     colorDark: 'red', //二维码前景色
                colorLight: '#fff', //二维码背景色
                text: this.list1.qrcode // 设置二维码内容或跳转地址
              })
            }
          },
            markover(it,index){
              if(!this.clickFlag) {
                this.currentMarker=index
                this.ms[index].show=true;
              }
            },
            markout(it,index){
              if(!this.clickFlag) this.ms[index].show=false;
            },
            infoWindowClose () {
              this.clickFlag=false
            },
            infoWindowOpen () {
              // this.clickFlag=true
            },
            //地图加载后的回调
            handlerReady({ BMap, map }) {
                //保存this指向，因为在百度的回调中this不指向vue
                const _this = this;
                // 获取自动定位方法
              _this.myMap = map;
              _this.myBMap = BMap;
                var geolocation = new BMap.Geolocation();
                // 获取自动定位获取的坐标信息
                geolocation.getCurrentPosition(
                    function (r) {
                        //可以conso.log看一下这个r，他里面包含了检索到的位置信息。下面就把两个维度信息赋值给center来定位
                        _this.center = {
                            lng: r.point.lng,
                            lat: r.point.lat,
                        };
                    },
                    //启用高精度
                    { enableHighAccuracy: true }
                );
            },
          handleNodeClick(data){
            // 乡镇名对应拼音三岔镇.json
            var jsonName = [{name: '庆远', json: 'qingyuan', center: {lng: 108.618325, lat: 24.432005}, zoom: 13,},
              {name: '怀远', json: 'huaiyuan', center: {lng: 108.466763, lat: 24.569137}, zoom: 13,},
              {name: '德胜', json: 'desheng', center: {lng: 108.294298, lat: 24.649045}, zoom: 13,},
              {name: '洛东', json: 'luodong', center: {lng: 108.860847, lat: 24.469521}, zoom: 13,},
              {name: '洛西', json: 'luoxi', center: {lng: 108.777863, lat: 24.421032}, zoom: 13,},
              {name: '石别', json: 'shibie', center: {lng: 108.65065, lat: 24.309075}, zoom: 13,},
              {name: '北山', json: 'beishan', center: {lng: 108.507192, lat: 24.293345}, zoom: 13,},
              {name: '刘三姐', json: 'liusanjie', center: {lng: 108.707959, lat: 24.550516}, zoom: 13,},
              {name: '龙头', json: 'longtou', center: {lng: 108.190481, lat: 24.505876}, zoom: 13,},
              {name: '同德', json: 'tongde', center: {lng: 108.404586 , lat: 24.463475}, zoom: 13,},
              {name: '屏南', json: 'pingnan', center: {lng: 108.884797, lat:  24.331878}, zoom: 13,},
              {name: '祥贝', json: 'xiangbei', center: {lng: 108.624951, lat: 24.662405}, zoom: 13,},
              {name: '安马', json: 'anma', center: {lng: 108.445202 , lat: 24.718456}, zoom: 13,},
              {name: '北牙', json: 'beiya', center: {lng: 108.335448 , lat: 24.405673}, zoom: 13,},
              {name: '福龙', json: 'fulong', center: {lng: 108.564457, lat: 24.162896}, zoom: 13,},
              {name: '三岔', json: 'sancha', center: {lng: 108.963421, lat: 24.428229}, zoom: 13,}]
            console.log(data.label);  // children: Array(3)   label: "安马"   $treeNodeId:  8
            var url = ''
            jsonName.forEach((it)=>{
              if(data.label===it.name) {
                url= '/json/'+it.json+'.json'
                // this.myCenter = it.center
                // this.myZoom = it.zoom
                // this.myMap.centerAndZoom(new BMap.Point(it.center.lng, it.center.lat), it.zoom)
              }
            })
            axios.get(url)
                .then(response => {
                   this.jsonData = response.data.features[0].geometry.coordinates[0]
                  var coordinates = response.data.features[0].geometry.coordinates[0]
                  //coordinates.forEach((it)=>{ console.log(it) })
                  // 将JSON数据转换为百度地图可以识别的坐标格式(原数据为GPS)
                  var polygonPath = coordinates.map(item => {
                    return new BMap.Point(item[0], item[1]);
                  })

                  this.request.post('/map-markers/jsonConvert', polygonPath)   //'jsonData':
                .then(res => {
                            if (res.code === '200') {
                              //console.log(res.data)
                              this.polygonPath =res.data
                              // 获取经纬度的最大和最小，在取平均
                              var arrX = [], arrY = [];
                              res.data.forEach((item)=>{
                                arrX.push(parseFloat(item.lng))
                                arrY.push(parseFloat(item.lat))
                              })
                              var minX = Math.min(...arrX);
                              var minY = Math.min(...arrY);
                              var maxX = Math.max(...arrX);
                              var maxY = Math.max(...arrY);
                              //console.log(arrX,"X=", (minX+maxX)/2, arrY,"Y=", (minY+maxY)/2)
                              this.myMap.centerAndZoom(new BMap.Point((minX+maxX)/2, (minY+maxY)/2), 12)
                            }
                          })
                  //console.log(this.polygonPath)
                })
                .catch(error => {
                  console.log(error)
                })

            if(data.id) {
              this.map={center: {lng: data.lng, lat: data.lat}, zoom: 11}
              console.log(this.map)
              this.markerNumber=data.id;
              this.ms[data.id].show=true;
              this.clickFlag=true
              this.list=this.lists[this.markerNumber]
            }
          },
          driving({point}){
            this.end=point; this.showDriving=true; this.showBC=true;
          },
          backToCenter(){//返回中心
            this.myMap.centerAndZoom(new BMap.Point(this.map.center.lng, this.map.center.lat), this.map.zoom) //修改中心点，缩放比例
          },
          downloadIamge11(imgsrc, name) {//下载图片地址和图片名
            let image = new Image();
            // 解决跨域 Canvas 污染问题
            image.setAttribute("crossOrigin", "anonymous");
            image.onload = function() {
              let canvas = document.createElement("canvas");
              canvas.width = image.width;
              canvas.height = image.height;
              let context = canvas.getContext("2d");
              context.drawImage(image, 0, 0, image.width, image.height);
              let url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
              let a = document.createElement("a"); // 生成一个a元素
              let event = new MouseEvent("click"); // 创建一个单击事件
              a.download = name || "photo"; // 设置图片名称
              a.href = url; // 将生成的URL设置为a.href属性
              a.dispatchEvent(event); // 触发a的单击事件
            };
            image.src = imgsrc;
          },
        },
    };
</script>

<style scoped>
    /* 给个宽高 */
    .bm-view {
        height: 800px;
        width: 100%;
    }
    .baidumap {
        height: 800px;
        width: 100%;
    }
    .input-with-select {
        width: 400px;
        margin-bottom: 5px;
    }
    .search {
        height: 300px;
        overflow: auto;
    }
    .openbox{
      margin: 5px;
      padding: 5px;
      background: #8c939d;
      font-size:12px;
      border-radius: 5px;
    }
    .openbox:hover{
      background: #6e809a;
      font-weight: 600;
    }
    .left{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30%;
      height: 110px;
      border: 2px solid #000;
    }
    .right{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70%;
      height: 110px;
      border: 2px solid #000;
    }
    .left1{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30%;
      /*height: 110px;*/
      border: 2px solid #000;
    }
    .right1{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70%;
      /*height: 110px;*/
      border: 2px solid #000;
    }
    th, td {
      border: 1px solid black; /* 设置边框 */
      padding: 5px; /* 设置内边距 */
      text-align: center; /* 设置文本对齐方式 */
    }
    .el-dialog__header {
      margin: 100px !important;
      background: #2A60C9 !important;
    }
    .el-dialog__body {
      padding: 100px !important;
      background: #2A60C9 !important;
    }
    .el-dialog__footer {
      background: #2A60C9 !important;
    }

    /*下面是bm-info-window的样式被改面目全非*/
    /*隐藏下箭头、上、中、下*/
    ::v-deep .BMap_pop img{
      display: none;
    }
    ::v-deep .BMap_top{
      display: none;
    }
    ::v-deep .BMap_center{
      display: none;
    }
    ::v-deep .BMap_bottom{
      display: none;
    }
    /*隐藏四个角的div块*/
    ::v-deep .BMap_pop > div:nth-child(1) {
      display: none !important;
    }
    ::v-deep .BMap_pop > div:nth-child(3) {
      display: none !important;
    }
    ::v-deep .BMap_pop > div:nth-child(5) {
      display: none !important;
    }
    ::v-deep .BMap_pop > div:nth-child(7) {
      display: none !important;
    }
    /*现在就剩下这个了！修改样式！*/
    /deep/.BMap_bubble_content {
      border: 3px solid #377abd;
      /*border-bottom: 3px solid #377abd;*/
      border-radius: 8px;
      background-color: rgba(36, 105, 137, 0.9);
      overflow: hidden;
      color: #ffffff;
      /*padding: 8px 5px;*/
      font-size: 14px;
      width: 240px !important;
    }
    .goto{
      font-size: 12px;
      padding-right: 10px;
    }
    .goto:hover{
      font-size: 13px;
      font-weight: 600;
      cursor: pointer;
      color: orange;
    }

/*   el-dialog样式修改 */
    ::v-deep .LjDialog .el-dialog {
      background-color: darkgrey;
      border-radius: 10px;
    }
    ::v-deep .LjDialog .el-dialog__header {
      padding: 10px;
      color: white;
      caret-color: transparent;
    }
    ::v-deep .LjDialog .el-dialog__body {
      padding: 10px;
      color: black;
      background: orange;
      caret-color: transparent;
    }
    ::v-deep .LjDialog .el-dialog__footer {
      padding: 10px 30px;
      caret-color: transparent;
    }
    ::v-deep .LjDialog .el-dialog__headerbtn {
      font-size: 30px;
      top: 10px;
      caret-color: transparent;
    }



</style>

